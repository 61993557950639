import React from "react";
import * as S from "./BoxBuySubscription.sc";
import Button from "../Button/Button";

const BoxBuySubscription = () => {
  if (process.env.step === 1) {
    return null;
  }
  return (
    <S.Box>
      <S.SmallHeader>Pełny dostęp do portalu?</S.SmallHeader>
      <S.Header>Wykup subskrybcje</S.Header>
      <S.Row>
        <S.Text>1 miesiąc</S.Text>
        <S.Label>24,99 zł</S.Label>
      </S.Row>
      <Button
        type="gradient"
        color="blue"
        onClickFunction={() => {}}
        customStyle={`margin-top: 0px;`}
      >
        SUBSKRYBUJ
      </Button>
    </S.Box>
  );
};

export default BoxBuySubscription;
