import React from "react";
import Link from "next/link";
import * as S from "./BoxNewsletter.sc";
import ButtonHref from "../Button/ButtonHref";

const BoxNewsletter = () => {
  if (process.env.step === 1) {
    return null;
  }
  return (
    <S.Box>
      <S.Header>Dołącz do newslettera</S.Header>
      <S.Text>
        Bądź na bieżąco
        <br />
        z&nbsp;informacjami prawniczymi
      </S.Text>

      <Link href={"/newsletter"} passHref={true}>
        <a>
          <ButtonHref
            type="gradient"
            color="blue"
            customStyle={`margin-top: 0px;`}
          >
            Zapisz się
          </ButtonHref>
        </a>
      </Link>
    </S.Box>
  );
};

export default BoxNewsletter;
