import styled from "styled-components";
import Link from "next/link";
import { device } from "../../services/styles";

export const Box = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  max-width: 320px;
  width: 100%;
  background-color: #f5f5f5;
  margin-bottom: 40px;
  @media ${device.desktop} {
    margin-bottom: 20px;
  }
`;
export const SmallHeader = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 10px 0;
  text-align: center;
  font-weight: 900;
`;
export const Header = styled.p`
  font-size: 28px;
  line-height: 1;
  margin: 0;
  text-align: center;
  font-weight: 900;
`;
export const Text = styled.span`
  font-size: 18px;
  line-height: 24px;
  margin: 0 20px 0 0;
  text-align: center;
`;

export const Label = styled.span`
  background-color: #de0008;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 1;
  font-weight: 900;
  color: #fff;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;
