import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.div`
  position: relative;
  z-index: 10;
  display: block;
  margin: 10px 10px 0;
  max-width: 320px;
  cursor: pointer;
  @media ${device.desktop} {
    max-width: ${(props) => (props.theme === "classic" ? "320px" : "512px")};
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 320px;
  height: 180px;
  overflow: hidden;
  @media ${device.desktop} {
    max-width: ${(props) => (props.theme === "classic" ? "320px" : "512px")};
    height: ${(props) => (props.theme === "classic" ? "180px" : "288px")};
  }
  margin-bottom: 5px;
`;

export const Category = styled.span`
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${(props) => `${props.color}`};
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 900;
`;

export const SpecialLabel = styled.span`
  display: inline-block;
  margin-bottom: -5px;
  padding: 4px 10px;
  background-color: #f1f1f1;
  color: #222222;
  font-size: 12px;
  line-height: 16px;
`;
export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Important = styled.span`
  display: inline-block;
  float: left;
  padding: 4px;
  margin-right: 5px;
  height: 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 900;
  background-color: #e6141b;
  color: #fff;
  @media ${device.desktop} {
    margin-top: 4px;
  }
`;
export const Title = styled.h3`
  position: relative;
  margin: ${(props) =>
    props.theme === "classic" ? "10px 0" : "10px 0 21px 0"};
  font-size: 22px;
  line-height: 26px;
  color: ${(props) => (props.theme === "classic" ? "#000" : "#fff")};
  &::after {
    content: "";
    display: ${(props) => (props.theme === "classic" ? "none" : "block")};
    width: 40px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    bottom: -10px;
  }
  @media ${device.desktop} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const Lead = styled.p`
  ${(props) =>
    props.theme === "classic"
      ? `
      margin: 0 0 10px 0;
      font-size: 14px;
      line-height: 20px;
      color: #000;
      @media ${device.desktop} {
        font-size: 16px;
        line-height: 24px;
      }`
      : `
      margin: 0 0 40px 0;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      @media ${device.desktop} {
        font-size: 16px;
        line-height: 28px;
      }
    `}
`;

export const ReadMoreLink = styled.span`
  display: block;
  margin-bottom: 30px;
  font-size: 14px;
  color: #4762ab;
  text-align: right;
  @media ${device.desktop} {
    font-size: 16px;
  }
`;

export const SitesLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 64px;
  height: 32px;
  padding: 3px;
  background-color: #fff;
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
