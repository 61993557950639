import React from "react";
import Link from "next/link";

import * as S from "./CategoriesSection.sc";
import { useCategoriesRequest } from "../../hooks/useCategoriesRequest";
import Button from "../Button/Button";

const CategoriesSection = ({ categories, count }) => {
  const [clientCategories, , getAllCategories] = useCategoriesRequest(
    {},
    { categories, count }
  );
  const categoriesList = clientCategories.filter(
    (el) => el.show_on_search_results === false
  );
  return (
    <S.Container>
      <S.Header>Kategorie</S.Header>
      <S.Row>
        {categoriesList.map((category) => (
          <Link
            key={`${category.id}`}
            href={`/artykuly/[category]`}
            as={`/artykuly/${category.slug}`}
            passHref={true}
          >
            <S.CategoryLabel>
              <S.IconContainer>
                {category.image && <S.Icon src={category.image} />}
                {category.image_list && (
                  <S.IconHover src={category.image_list} />
                )}
              </S.IconContainer>
              <S.Text>{category.name}</S.Text>
            </S.CategoryLabel>
          </Link>
        ))}
      </S.Row>
      {categoriesList.length < count && (
        <Button
          onClickFunction={getAllCategories}
          color={"blue"}
          type={"gradient"}
        >
          ZOBACZ WSZYSTKIE
        </Button>
      )}
    </S.Container>
  );
};
export default CategoriesSection;
