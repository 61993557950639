import React from "react";
import styled from "styled-components";
import ArticlesHighlighted from "../components/ArticlesHighlighted/ArticlesHighlighted";
import ArticlesSection from "../components/ArticlesSection/ArticlesSection";
import CategoriesSection from "../components/CategoriesSection/CategoriesSection";
import BoxLegalAdvice from "../components/BoxLegalAdvice/BoxLegalAdvice";
import BoxNewsletter from "../components/BoxNewsletter/BoxNewsletter";
import Ado from "../components/Ado/Ado";
import AdoCodes from "../components/Ado/AdoConfig.json";
import BoxBuySubscription from "../components/BoxBuySubscription/BoxBuySubscription";
import SpecialOfferSection from "../components/SpecialOfferSection/SpecialOfferSection";
import ArticleAuthors from "../components/ArticleAuthors/ArticleAuthors";

import { device } from "../services/styles";
import {
  getHomepageRows,
  getMainCategories,
  getHomepageRowsArticles,
  getArticles,
  fetchApi,
} from "../services/requests";
import { getLayoutProps } from "../services/serverSideRequests";
import { getAdData } from "../services/adsStrings";
import Layout from "../providers/Layout";
import Link from "next/link";

const Container = styled.div`
  max-width: 1240px;
  width: 100%;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
const AdoCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const Aside = styled.div`
  max-width: 320px;
`;
const Home = ({
  categories,
  homepageRows,
  homepageArticles,
  latestArticles,
  navbarLinks,
  submenuLists,
  paths,
  mainCategories,
  importantArticles,
  specialOffer,
  articleAuthors,
}) => {
  const getArticlesByIndex = (index) => homepageArticles[index][0] || [];
  const journalists =
    articleAuthors && articleAuthors[0]
      ? articleAuthors[0].results.filter(
          (author) => author.hide_on_homepage === false
        )
      : [];
  return (
    <Layout
      navbarLinks={navbarLinks}
      paths={paths}
      submenuLists={submenuLists}
      mainCategories={mainCategories}
      importantArticles={importantArticles}
      metatagsData={{ title: "Portal agrarlex.pl" }}
    >
      <Container>
        <Row>
          <div>
            <ArticlesHighlighted params={{ limit: 3 }} />
            {categories[0] && (
              <CategoriesSection
                categories={categories[0].results}
                count={categories[0].count}
              />
            )}
          </div>
          <Aside>
            <BoxLegalAdvice />
            <BoxBuySubscription />
            <BoxNewsletter />
            <Ado {...AdoCodes.id100homepageSidebar1} />
          </Aside>
        </Row>
        <ArticlesSection
          params={{ limit: 5, offset: 3 }}
          category={"Najnowsze"}
          articles={latestArticles[0] || []}
        />

        {homepageRows.map((row, index) => (
          // TODO homepage rows
          <div key={row.pk}>
            <ArticlesSection
              params={{ limit: 5, main_category: row.category }}
              articles={getArticlesByIndex(index)}
            />
            {row.adocean_after && (
              <AdoCenter>
                <Ado {...getAdData(row.adocean_after)} />
              </AdoCenter>
            )}
          </div>
        ))}
        {journalists.length !== 0 && (
          <ArticleAuthors authors={journalists} title={"Piszą dla nas"} />
        )}
        {specialOffer[0] && <SpecialOfferSection offers={specialOffer[0]} />}
      </Container>
    </Layout>
  );
};

export async function getServerSideProps(context) {
  const homepageRows = await getHomepageRows();
  const categories = await getMainCategories();
  const homepageArticles = await getHomepageRowsArticles(homepageRows);
  const latestArticles = await getArticles({ limit: 5, offset: 3, site: 12 });
  const navbarLinksData = await fetchApi({ url: "pages-link-list/42" });
  const layoutProps = await getLayoutProps();
  const specialOffer = await fetchApi({
    url: "agrarlex-special-offer/?homepage=True&limit=2&random=True&site=12",
  });
  const articleAuthors = await fetchApi({
    url: "contact-journalists/?site=12",
  });
  return {
    props: {
      homepageRows,
      categories,
      homepageArticles,
      latestArticles,
      ...layoutProps,
      specialOffer,
      articleAuthors,
    },
  };
}

export default Home;
