import { useState, useEffect } from "react";
import { fetchApi } from "../services/requests";

export function useCategoryRequest(id) {
  let [data, setData] = useState(null);
  let [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const data = await fetchApi({ url: `articles-category/${id}` });
      if (data[0]) {
        setData(data[0]);
      }
      if (data[1]) {
        setError(data[1]);
      }
    };
    getData();
  }, []);

  return [data, error];
}
