import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.div`
  position: relative;
  z-index: 10;
  display: block;
  margin: ${(props) =>
    props.hasborder === "true" ? "34px 10px 20px 10px" : "10px 10px 20px 10px"};
  max-width: 320px;
  display: flex;
  border: ${(props) =>
    props.hasborder === "true" ? "2px solid #F1F1F1" : "none"};
  padding: ${(props) => (props.hasborder === "true" ? "10px" : "0")};
  &:first-of-type {
    border-bottom: ${(props) =>
      props.theme === "classic" ? "auto" : "1px solid rgba(255,255,255,0.26)"};
  }
  cursor: pointer;
  @media ${device.desktop} {
    max-width: ${(props) => (props.theme === "classic" ? "300px" : "288px")};
    margin-bottom: ${(props) => (props.theme === "classic" ? "34px" : "20px")};
    flex-direction: column;
    &:first-of-type {
      border-bottom: ${(props) =>
        props.hasborder === "true" ? "2px solid #F1F1F1" : "none;"};
    }
  }
`;

export const TitleRow = styled.div``;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 112px;
  height: 63px;
  overflow: hidden;
  margin: 0 10px 10px 0;
  @media ${device.desktop} {
    max-width: 288px;
    height: 162px;
  }
`;
export const Labels = styled.div`
  display: flex;
  align-items: center;
`;
export const Category = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${(props) => `${props.color}`};
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 900;
  @media ${device.desktop} {
    position: ${(props) => (props.theme === "classic" ? "static" : "absolute")};
    top: 4px;
    left: 4px;
  }
`;

export const LogoLabel = styled.span`
  display: inline-block;
  position: relative;
  width: 48px;
  height: 24px;
  margin-left: 10px;
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.3);
`;
export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Important = styled.span`
  display: inline-block;
  float: left;
  padding: 4px;
  margin-right: 5px;
  height: 24px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 900;
  background-color: #e6141b;
  color: #fff;
`;

export const SpecialPublication = styled.span`
  display: inline-block;
  position: ${(props) => (props.hasborder === "true" ? "absolute" : "static")};
  top: -24px;
  left: -2px;
  padding: 5px 10px;
  height: 24px;
  width: ${(props) =>
    props.hasborder === "true" ? "calc(100% + 4px)" : "auto"};
  font-size: 12px;
  line-height: 16px;
  background-color: #f1f1f1;
  color: #000;
`;

const titleClassic = (color) => `
  margin: 10px 0 14px 0;
  font-size: 22px;
  line-height: 24px;
  color: #000;
  @media ${device.desktop} {
    font-size: 24px;
    line-height: 28px;
  }
  &::after {
    content: "";
    display: block;
    width: 40px;
    height: 4px;
    background-color: ${color};
    position: absolute;
    bottom: -14px;
  }
`;

const titleSlider = `
margin: 0 0 21px 0;
font-size: 18px;
line-height: 26px;
color: #fff;
@media ${device.desktop} {
  font-size: 20px;
  line-height: 26px;
}
`;

export const Title = styled.h3`
  position: relative;
  ${(props) =>
    props.theme === "classic" ? titleClassic(props.color) : titleSlider}

  @media ${device.desktop} {
    margin-bottom: ${(props) => (props.hasborder === "true" ? "14px" : "0")};
  }
`;

export const SitesLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 64px;
  height: 32px;
  padding: 3px;
  background-color: #fff;
`;

export const RelativeSitesLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 64px;
  height: 32px;
  padding: 3px;
  background-color: #fff;
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
