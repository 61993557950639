import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 1050px;
  margin: 50px auto;
  @media ${device.desktop} {
    margin: 80px auto;
  }
`;

export const Header = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  font-weight: 900;
  @media ${device.desktop} {
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Column = styled.div`
  @media ${device.tablet} {
    margin-left: 20px;
  }
`;

export const DesktopSpan = styled.span`
  display: none;
  @media ${device.desktop} {
    display: block;
  }
`;

export const MobileSpan = styled.span`
  display: block;
  @media ${device.desktop} {
    display: none;
  }
`;
