import React from "react";
import Link from "next/link";
import * as S from "./ArticleTeaser.sc";
import {
  replaceDomainToTap,
  getRelativeArticlePath,
  getRelativeArticleServicesPath,
  getArticleSlug,
} from "../../services/urlString";
import { compareDate } from "../../services/date";
import { LOGO_URL } from "../../services/logoUrl";

/**
 * ArticleTeaser component
 * @function ArticleTeaser
 * @param {Object} props
 * @param {Object} props.article - props - article data
 * @param {string} props.theme - "slider" | "classic";
 *
 */

const labelColors = {
  red: "#E6141B",
  green: "#339D37",
  navyBlue: "#4762AB",
  blue: "#4D75E1",
  claret: "#9A1C20",
};

const ArticleTeaser = ({ article, theme }) => {
  const {
    recommended,
    important_until,
    get_external_url,
    image_cropped,
    category,
    title,
    site,
  } = article;
  const labelColor =
    theme === "slider" ? labelColors.claret : labelColors.navyBlue;
  const fromAgrarlex = site.id === 12;
  const hasBorder = theme === "classic" && recommended ? true : false;
  const isImportant = compareDate(new Date(important_until), new Date());
  const categorySlug = article.category.slug;
  const articleSlug = getArticleSlug(article.get_external_url);
  const href = fromAgrarlex
    ? "/artykuly/[category]/[article]"
    : "/artykuly-z-serwisow/[article]";
  const as = fromAgrarlex
    ? `/artykuly/${categorySlug}/${articleSlug}`
    : `/artykuly-z-serwisow/${articleSlug}`;
  const croppedImage = article.crops.find((el) => el.ratio === "320x180");
  return (
    <Link href={href} as={as} passHref={true}>
      <a>
        <S.Container theme={theme} hasborder={hasBorder.toString()}>
          {theme === "slider" && (
            <S.ImageContainer>
              {!fromAgrarlex && (
                <S.SitesLabel>
                  <S.Logo src={LOGO_URL[article.site.id]} />
                </S.SitesLabel>
              )}
              {image_cropped && (
                <S.Image
                  src={replaceDomainToTap(croppedImage?.image || image_cropped)}
                />
              )}
            </S.ImageContainer>
          )}
          <S.TitleRow>
            {/* TODO - style for recommendend */}
            {article.advertising && (
              <S.SpecialPublication hasborder={hasBorder.toString()}>
                Publikacja specjalna
              </S.SpecialPublication>
            )}
            {isImportant && !recommended && <S.Important>WAŻNE</S.Important>}
            <S.Labels>
              {category && !recommended && (
                <S.Category color={labelColor} theme={theme}>
                  {category.name}
                </S.Category>
              )}
              {theme !== "slider" && !fromAgrarlex && (
                <S.RelativeSitesLabel>
                  <S.Logo src={LOGO_URL[article.site.id]} />
                </S.RelativeSitesLabel>
              )}
            </S.Labels>

            <S.Title
              theme={theme}
              color={labelColor}
              hasborder={hasBorder.toString()}
            >
              {title}
            </S.Title>
          </S.TitleRow>
        </S.Container>
      </a>
    </Link>
  );
};

export default ArticleTeaser;
