import React from "react";
import Link from "next/link";
import * as S from "./ArticlesSection.sc";
import ButtonHref from "../Button/ButtonHref";
import { useCategoryRequest } from "../../hooks/useCategoryRequest";

const ArticlesSectionButton = ({ id }) => {
  const [data] = useCategoryRequest(id);

  if (data) {
    return (
      <Link
        href={`/artykuly/[category]`}
        as={`/artykuly/${data.slug}`}
        passHref={true}
      >
        <a>
          <ButtonHref type={"gradient"} color={"blue"}>
            <>
              <S.DesktopSpan>ZOBACZ CAŁĄ KATEGORIE</S.DesktopSpan>
              <S.MobileSpan>ZOBACZ WSZYSTKIE</S.MobileSpan>
            </>
          </ButtonHref>
        </a>
      </Link>
    );
  } else {
    return null;
  }
};

export default ArticlesSectionButton;
