import React from "react";
import * as S from "./BoxLegalAdvice.sc";
import Link from "next/link";

const BoxLegalAdvice = () => {
  return (
    <S.Box>
      <S.Header>Potrzebujesz porady?</S.Header>
      <Link href={"/[text-site]"} as={"/porady-prawne"} passHref={true}>
        <S.Button
          color={"#339D37"}
          icon={"/images/iconSmall.png"}
          iconhover={"/images/iconHoverSmall.png"}
        >
          zadaj pytanie redakcji
        </S.Button>
      </Link>
      {/* <Link href={"/[text-site]"} as={"/porady-prawne"} passHref={true}>
        <S.Button
          color={"#32B837"}
          icon={"/images/iconLarge.png"}
          iconhover={"/images/iconHoverLarge.png"}
        >
          Skorzystaj z pomocy prawnika
        </S.Button>
      </Link> */}
    </S.Box>
  );
};

export default BoxLegalAdvice;
