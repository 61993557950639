import React from "react";
import Link from "next/link";
import * as S from "./SpecialOfferBox.sc";
import ButtonHref from "../Button/ButtonHref";
import { ThemeProvider } from "styled-components";
import stripTags from "striptags";

const ACTION_TEXT = {
  event: "ZOBACZ WYDARZENIE",
  offer: "SPRAWDŹ OFERTĘ",
};

/**
 * Button component
 * @function SpecialOfferBox
 * @param {Object} props
 * @param {string} props.offer - item data
 * @param {string} props.placeType  - "page" (default) | "sidebar"
 *
 */
const SpecialOfferBox = ({ offer, placeType }) => {
  return (
    <ThemeProvider theme={{ placeType }}>
      <S.Container>
        <S.ImageContainer>
          <S.Image src={offer.image} />
        </S.ImageContainer>
        <S.Column>
          <S.Header>{offer.title}</S.Header>
          {offer.address && <S.Address>{offer.address}</S.Address>}
          {offer.text && <S.Description>{stripTags(offer.text)}</S.Description>}
          {offer.date && <S.Time>Data: {offer.date}</S.Time>}
          {offer.start_time && (
            <S.Time>
              {`Godz.: ${offer.start_time.slice(0, 5)} - ${offer.end_time.slice(
                0,
                5
              )}`}
            </S.Time>
          )}
          <Link href={offer.url} passHref={true}>
            <S.LinkHtml href={offer.url}>
              <ButtonHref
                type={"border"}
                color={"blue"}
                customStyle={{ marginTop: 20 }}
              >
                {ACTION_TEXT[offer.template]}
              </ButtonHref>
            </S.LinkHtml>
          </Link>
        </S.Column>
      </S.Container>
    </ThemeProvider>
  );
};

export default SpecialOfferBox;
