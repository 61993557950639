export const compareDate = (date1, date2) => date1.getTime() >= date2.getTime();

export const convertDate = (dateFromApi) => {
  const date = new Date(dateFromApi);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${day}.${month}.${year}`;
};

export const convertTime = (dateFromApi) => {
  const date = new Date(dateFromApi);
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${hour}:${minutes}:${seconds}`;
};

export const addHalfYearToDate = (dateFromApi) => {
  const date = new Date(dateFromApi);
  const dateTime = date.getTime();
  const halfYearMiliseconds = (364 / 2) * 24 * 60 * 60 * 1000;
  return new Date(dateTime + halfYearMiliseconds);
};

export const passedMoreThanHalfYear = (dateFromApi) => {
  const now = new Date();
  const comparedDate = addHalfYearToDate(dateFromApi);
  return compareDate(now, comparedDate);
};

export const getOneYearAgo = () => {
  const date = new Date();
  const pastYear = date.getFullYear() - 1;
  return date.setFullYear(pastYear);
};
