import styled from "styled-components";
import { device } from "../../services/styles";

export const Box = styled.div`
  padding: 20px;
  max-width: 320px;
  width: 100%;
  border: 2px solid #dddddd;
  margin-bottom: 40px;
  @media ${device.desktop} {
    margin-bottom: 20px;
  }
`;
export const Header = styled.p`
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 20px 0;
  text-align: center;
  font-weight: 900;
`;

export const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px;
  margin-top: 20px;
  width: 100%;
  min-height: 54px;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 900;
  color: #fff;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  background-image: url(${(props) => props.icon});
  background-position: left center;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
  cursor: pointer;
  &:hover {
    background: url(${(props) => props.iconhover}) rgb(77, 117, 225);
    background: url(${(props) => props.iconhover})
      linear-gradient(
        118deg,
        rgba(77, 117, 225, 1) 0%,
        rgba(71, 98, 171, 1) 100%
      );
  }
`;
