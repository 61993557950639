import styled from "styled-components";
import { device } from "../../services/styles";
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px 10px 0 10px;
  background-color: #6286e8;
  max-width: 880px;
  width: 100%;
  overflow: hidden;
`;

export const Circles = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: radial-gradient(farthest-side, #7291e8 100%, transparent) 300px
      110px/360px 360px,
    radial-gradient(farthest-side, #7291e8 100%, transparent) -270px 360px/600px
      600px,
    radial-gradient(farthest-side, #7291e8 100%, transparent) 370px 540px/60px
      60px,
    radial-gradient(farthest-side, #7291e8 100%, transparent) 420px 680px/30px
      30px,
    radial-gradient(farthest-side, #7291e8 100%, transparent) 470px 540px/610px
      610px,
    radial-gradient(farthest-side, #7291e8 100%, transparent) 700px -10px /370px
      370px;
  background-repeat: no-repeat;
`;

export const Header = styled.p`
  margin: 0 0 20px;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  font-weight: 900;
  @media ${device.desktop} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Column = styled.div`
  @media ${device.tablet} {
    margin-left: 20px;
  }
`;
