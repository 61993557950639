import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 320px;
  background-color: #f5f5f5;
  ${({ theme }) => {
    if (theme.placeType === "sidebar") {
      return `
            margin: 20px 0;
            padding: 20px 10px 72px 10px;
        `;
    } else {
      return `
      flex-direction: column;
      @media ${device.desktop} {
        flex-direction: row;
        align-items: flex-start;
        width: 488px;
        margin: 0 20px 20px 20px;

      }
        `;
    }
  }}
`;
export const Header = styled.p`
  line-height: 1;
  margin-bottom: 4px;
  font-weight: 900;
  color: #4762ab;
  ${({ theme }) => {
    if (theme.placeType === "sidebar") {
      return `
        font-size: 18px;
        margin: 0 0 10px 0;
        `;
    } else {
      return `
        font-size: 24px;
        margin: 0 0 4px 0;
        `;
    }
  }}
`;

export const LinkHtml = styled.a`
  ${({ theme }) => {
    if (theme.placeType === "sidebar") {
      return `
        position: absolute;
        bottom: 14px;
        left: 0;
        width: 100%;
        text-align: center;
      `;
    }
  }}
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${({ theme }) => {
    if (theme.placeType === "sidebar") {
      return `
      width: 120px;
      height: 120px;
        `;
    } else {
      return `
        width: 100%;
        height: 320px;
        @media ${device.desktop} {
            width: 208px;
            height: 248px;
        }
        `;
    }
  }}
`;

export const Image = styled.img`
  width: auto;
  height: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${({ theme }) => {
    if (theme.placeType === "sidebar") {
      return `
        width: calc(100% - 120px);
        padding: 0 0 0 10px;
        `;
    } else {
      return `
      width: 100%;
        padding: 20px;
      @media ${device.desktop} {
        width: calc(100% - 248px);
        padding: 20px 0 20px 20px;
      }
        `;
    }
  }}
`;

export const Address = styled.span`
  margin-bottom: 20px;
  ${({ theme }) => {
    if (theme.placeType === "sidebar") {
      return `
      font-size: 14px;
      line-height: 16px;
        `;
    } else {
      return `
      font-size: 18px;
      line-height: 20px;
       `;
    }
  }}
`;
export const Description = styled.span`
  line-height: 20px;
  ${({ theme }) => {
    if (theme.placeType === "sidebar") {
      return `
      font-size: 14px;
      max-height: 60px;
      overflow:hidden;
        `;
    } else {
      return `
        font-size: 16px;
       `;
    }
  }}
`;

export const Time = styled.span`
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  &:last-of-type {
    margin-bottom: 10px;
  }
  ${({ theme }) => {
    if (theme.placeType === "sidebar") {
      return `
      font-size: 18px;
      line-height: 24px;
        `;
    } else {
      return `
      font-size: 24px;
      line-height: 28px;
       `;
    }
  }}
`;
