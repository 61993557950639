import React, { useEffect, useState } from "react";

import * as S from "./ArticlesSection.sc";
import ArticleTeaserLarge from "../ArticleTeaserLarge/ArticleTeaserLarge";
import ArticleTeaser from "../ArticleTeaser/ArticleTeaser";
import ArticlesSectionButton from "./ArticlesSectionButton";

const ArticlesSection = ({ category, params, articles }) => {
  const [data, setData] = useState(articles);

  const getHeader = ({ category }) =>
    category.main_category ? category.main_category.name : category.name;
  if (data && data.count >= 2) {
    return (
      <S.Container>
        <>
          <S.Header>{category || getHeader(data.results[0])}</S.Header>
          <S.Row>
            <ArticleTeaserLarge article={data.results[0]} theme={"classic"} />
            <ArticleTeaserLarge article={data.results[1]} theme={"classic"} />
            {data.count === 3 && (
              <ArticleTeaserLarge article={data.results[2]} theme={"classic"} />
            )}
            {data.count >= 5 && (
              <div>
                <ArticleTeaser article={data.results[2]} theme={"classic"} />
                <ArticleTeaser article={data.results[3]} theme={"classic"} />
                <ArticleTeaser article={data.results[4]} theme={"classic"} />
              </div>
            )}
          </S.Row>
          {!category && <ArticlesSectionButton id={params.main_category} />}
        </>
      </S.Container>
    );
  } else {
    return null;
  }
};

export default ArticlesSection;
