import { replace } from "lodash";

export const getAdId = (string) => {
  const firstpart = replace(string, "ado.slave('", "");
  const secondpart = replace(firstpart, /', {myMaster:.*/, "");
  return secondpart;
};

export const getAdPlacementId = (string) => {
  const firstpart = replace(string, /.*{myMaster: '/, "");
  const secondpart = replace(firstpart, `' });`, "");
  return secondpart;
};

export const getAdData = (string) => ({
  id: getAdId(string),
  placementId: getAdPlacementId(string),
});
