import { useState, useEffect } from "react";
import { fetchApi, buildArrayWithOffsetValues } from "../services/requests";
import { flattenArrayOfArrays } from "../services/arrays";
import { compose } from "../services/utility";
import queryString from "query-string";

const defaultParams = {
  site: 12,
  only_main: "True",
  limit: 12,
};

export function useCategoriesRequest(params, initialData) {
  const [categories, setCategories] = useState(
    (initialData && initialData.categories) || []
  );
  const [downloadedAll, setDownloadedAll] = useState(false);
  const [count, setCount] = useState((initialData && initialData.count) || 0);
  const [error, setError] = useState(null);
  const requestParams = { ...defaultParams, ...params };

  const fetchData = async (offset) => {
    const query = offset
      ? queryString.stringify({ ...requestParams, ...offset })
      : queryString.stringify(requestParams);
    const data = await fetchApi({ url: `articles-category/?${query}` });
    return data;
  };

  const getData = async () => {
    const data = await fetchData();
    if (data[0]) {
      setCategories(data[0].results);
      setCount(data[0].count);
    }
    if (data[1]) {
      setError(data[1]);
    }
  };

  useEffect(() => {
    !categories.length && getData();
  }, []);

  const getAllCategories = () => {
    if (!downloadedAll) {
      setDownloadedAll(true);
      const offsets = buildArrayWithOffsetValues(count, 12).slice(1);
      const promisses = Promise.all(
        offsets.map((offset) => fetchData({ offset }))
      );
      promisses.then((results) => {
        const data = compose(
          flattenArrayOfArrays,
          getCategoryFromResponse
        )(results);
        setCategories([...categories, ...data]);
      });
    }
  };

  const getCategoryFromResponse = (response) =>
    response.map((resp) => (resp[0] ? resp[0].results : []));

  return [categories, error, getAllCategories];
}
