import React from "react";
import Link from "next/link";
import * as S from "./ArticleTeaserLarge.sc";
import ReactHtmlParser from "react-html-parser";
import striptags from "striptags";
import { replaceDomainToTap, getArticleSlug } from "../../services/urlString";
import { compareDate } from "../../services/date";
import { LOGO_URL } from "../../services/logoUrl";

/**
 * ArticleTeaserLarge component
 * @function ArticleTeaserLarge
 * @param {Object} props
 * @param {Object} props.article - props - article data
 * @param {string} props.theme - "slider" | "classic";
 *
 */

const labelColors = {
  red: "#E6141B",
  green: "#339D37",
  navyBlue: "#4762AB",
  blue: "#4D75E1",
  claret: "#9A1C20",
};

const ArticleTeaserLarge = ({ article, theme }) => {
  const fromAgrarlex = article.site.id === 12;
  const labelColor =
    theme === "slider" ? labelColors.claret : labelColors.navyBlue;
  const isImportant = compareDate(
    new Date(article.important_until),
    new Date()
  );
  const categorySlug = article.category.slug;
  const articleSlug = getArticleSlug(article.get_external_url);
  const href = fromAgrarlex
    ? "/artykuly/[category]/[article]"
    : "/artykuly-z-serwisow/[article]";
  const as = fromAgrarlex
    ? `/artykuly/${categorySlug}/${articleSlug}`
    : `/artykuly-z-serwisow/${articleSlug}`;

  const croppedImage =
    theme === "slider"
      ? article.crops.find((el) => el.ratio === "512x288")
      : article.crops.find((el) => el.ratio === "320x180");
  return (
    <Link href={href} as={as} passHref={true}>
      <a>
        <S.Container theme={theme}>
          <S.ImageContainer theme={theme}>
            {article.category && (
              <S.Category color={labelColor}>
                {article.category.name}
              </S.Category>
            )}
            {!fromAgrarlex && (
              <S.SitesLabel>
                <S.Logo src={LOGO_URL[article.site.id]} />
              </S.SitesLabel>
            )}
            {article.image_cropped && (
              <S.Image
                src={replaceDomainToTap(
                  croppedImage?.image || article.image_cropped
                )}
              />
            )}
          </S.ImageContainer>
          <div>
            {article.advertising && (
              <S.SpecialLabel>Publikacja specjalna</S.SpecialLabel>
            )}
            <div>
              {isImportant && <S.Important>WAŻNE</S.Important>}
              <S.Title theme={theme}>{article.title}</S.Title>
              <S.Lead theme={theme}>{striptags(article.lead)}</S.Lead>
              {theme === "classic" && (
                <S.ReadMoreLink>czytaj więcej</S.ReadMoreLink>
              )}
            </div>
          </div>
        </S.Container>
      </a>
    </Link>
  );
};

export default ArticleTeaserLarge;
