import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 760px;
  margin: 50px auto;
  @media ${device.desktop} {
    margin: 80px auto;
  }
`;

export const Header = styled.p`
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  font-weight: 900;
  @media ${device.desktop} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media ${device.desktop} {
    justify-content: space-between;
  }
`;
export const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #e6e6e6;
  margin-right: 20px;
`;
export const Icon = styled.img`
  max-width: 42px;
  max-height: 42px;
`;
export const IconHover = styled(Icon)`
  display: none;
`;

export const Text = styled.span`
  color: #000;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  width: calc(100% - 80px);
`;
export const CategoryLabel = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 320px;
  padding: 10px 20px;
  margin-bottom: 1px;
  background-color: #f5f5f5;
  cursor: pointer;
  @media ${device.desktop} {
    max-width: 240px;
    margin-bottom: 20px;
  }

  &:hover {
    background-color: #456dd6;
  }

  &:hover ${IconContainer} {
    background-color: #fff;
  }
  &:hover ${Text} {
    color: #fff;
  }

  &:hover ${Icon} {
    display: none;
  }
  &:hover ${IconHover} {
    display: block;
  }
`;
