import React from "react";
import styled from "styled-components";
import SpecialOfferBox from "../SpecialOfferBox/SpecialOfferBox";
import { device } from "../../services/styles";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`;
const Header = styled.p`
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 40px;
  font-weight: 900;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

function SpecialOfferSection({ offers }) {
  if (offers) {
    return (
      <Container>
        <Header>Oferta specjalna</Header>
        <Row>
          {offers.results.map((el) => (
            <SpecialOfferBox offer={el} key={`specialOffer${el.pk}`} />
          ))}
        </Row>
      </Container>
    );
  } else return null;
}

export default SpecialOfferSection;
