import React from "react";
import * as S from "./ArticlesHighlighted.sc";
import { useArticlesRequest } from "../../hooks/useArticlesRequest";
import ArticleTeaserLarge from "../ArticleTeaserLarge/ArticleTeaserLarge";
import ArticleTeaser from "../ArticleTeaser/ArticleTeaser";

const ArticlesHighlighted = ({ params }) => {
  const [articles] = useArticlesRequest(params);

  if (!articles || !articles.count) {
    return null;
  }

  return (
    <S.Container>
      <S.Circles />
      <S.Header>Najnowsze artykuły</S.Header>
      {articles && articles.results.length >= 3 && (
        <S.Row>
          <ArticleTeaserLarge article={articles.results[0]} theme={"slider"} />
          <div data-testid={"article1"}>
            <ArticleTeaser article={articles.results[1]} theme={"slider"} />
            <ArticleTeaser article={articles.results[2]} theme={"slider"} />
          </div>
        </S.Row>
      )}
    </S.Container>
  );
};

export default ArticlesHighlighted;
